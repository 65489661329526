import React, { useState }from "react"
import {Button, Col, Container, Input, Row} from 'reactstrap'
import classnames from 'classnames';
import {graphql} from "gatsby";
import * as turf from "@turf/turf";
import {Marker, Polyline, Popup, TileLayer} from "react-leaflet";
import polyUtil from "polyline-encoded";

import Layout from "../components/layout"
import PageTitle from '../components/pageTitle'
import SEO from "../components/seo"
import {LangContext} from "../components/lurraldebusWrapper";
import Map from "../components/map";
import MapMarkerParadaIcon from "../components/mapMarkerParadaIcon";
import IdaIcon from "../assets/icon/ida.svg"
import VueltaIcon from "../assets/icon/vuelta.svg"
import OvalParadaIcon from "../assets/icon/oval-parada.svg"
import FlechaOval from "../assets/icon/flecha-ciclo.svg"

const LineasYHorariosPage = ({data}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [activeSubTab, setActiveSubTab] = useState('itinerarios');

  const toggle = tab => {
    if (tab === null) return;
    if (activeTab !== tab) setActiveTab(tab);
  }

  const toggleSubTab = subtab => {
    setActiveSubTab(subtab);
  }

  const redOptions = { color: '#f69f06', smoothFactor: 0.1 }

  const geometriasRutas = {
    'BU01': [
      "{`_gGt`jKsAzAIb@nCgBbBiToUgEgA]GsF`HrAcDpE`TrDSrK_a@}GwM_N{YqAoPoCi@hDdAvLpB_B`AtDfG}A~C}@OwAYfBmJrCyNLq@CgMsIqBgA}EkJvAiCxDjAgITsSuGuEim@y@{EgWwZmBQmOwMiF{EkMWo@u@ac@sKwFnDaOrZqLxIkEjFeL~Ie@p@iObPoF|EuJvAeHwL{Tek@BuFs@hDdCxHzCtHqFcPxDaa@iDen@y@{CyHiY`QqKvDuB~JPG{Ewi@gAgAz@kAEwE|Co@b@`DnL",
      "azkgGldbKjEjOxBgApOlW|Clj@eFt\\bDjLnWfn@zK~@f}@}y@nD_CFg@ECjKqOpe@`FvNqApGfFzPbOm@k@wUcT{O}Aac@sKwFnDaOrZqLxIqRjQ_XrXiLr@cMqXQuBP|CnGzOrFnHlHQfIoG|KaKjDuCdJuKnU_TnD_CFg@ECjKqOtb@vCzAhA`NuA|FxDzCtFz@ZlKbH~VbYvAnD|CfRgCpRj@nAzC_H{D_^q@_ENtC|CfREjX~BpHxDdFvMt@|DzEbBLhLvI`Dt@bNElFcBrYaItDcArGQxNnDhStExAEeAnQqB_@Mv@p@j@"
    ],
    'BU02': [
      "y`_gGv`jKEbBoAIIb@~AFn@oB|@Wd@qSkADcSmEgA]GsF`HrAsAXoAvDjMpDtE@SrK_a@}GwM_NyLTaLgBoPoCc@e@EnEdAvLpB_B`AtDfG}A~C}@OwAYfBmJrCyNLq@CgMsIqBgA}EkJvAiCxDjAO~@wHi@_KcAsGqEmAwG?gRgCiQy@{EbBqRxEsTgDw_@hCsd@x@_BXaBaC[gE]i@s@_@t@kE\\kIpEyFrHmALaFcGqFwOwDoRc@wJmKwE_CwDw@_@}CdF??CNb@pAEuCzBuB|@g@_HiUkJaUs@}@e@`AnCfBzBnGvBhH`AZkJaUc@_F",
      "mwfgGxm_K`BhL@rC`HvOhAdEbH|UyChFw@LkSsHkFwIyApA`AfGiBl@QsKbHuDsNiAbAgQkGeGjDaKqAqFeDlAsAaDlAs@@dB{O|AuFdNwAfOGjDuArBVhAdAIrEzLeCzPyJtNyNaCcDfQm@|]{FxWgB`GiApDpKwGYdNnErEpMvE~@vJlCiC|AyJdAZ`EbVmIlIdFbOjSnFxc@rx@jSlMdd@iC~Q~A~FoHh@i@lCmSjFkK{Dy^q@_ENtC|CfREjX~BpHxDdFvMt@|DzEbBLhLvI`Dt@bNElFcBrYaItDcArGQxNnDhStE"
    ],
    'BU03': [
      "yjvfGlmkKcKqU}CqFoGgKoAeLoNU@Q@o@C`Aq@?oNbNs@h@yTbL{CL}HqHbDfEKc@[y@oCiEw@fEx@|KjEmChALLCnA]l]_EtA|BqFaLyTbLov@e@_d@pZuSeE}`@{k@mMwGkXgD{WeEgA]GsF`HrAcDpE`TrDSrK_a@}GwM_N{YqAoPoCi@hDdAvLpB_B`AtDfG}A~C}@OwAYfBmJrCyNLq@CgMsIqBgA}EkJpG}@gITsSuGuEim@y@{EgWwZmBQmOwMuTsFo@u@ac@sKyVb`@qLxIkEjFeL~Ie@p@iObPoF|EuJvAeHwL{Tek@BuFpAbNzCtHqFcPxDaa@iDen@y@{CyHiY`QqKvDuB~JPG{Ewi@gAgAz@kAEwE|Co@b@`DnL",
      "azkgGldbKjEjOxBgAdBzCjLpRzAnuAxJlVnGzO`P|GfIoG|KaKjDuCdJuKnU_TnD_CFg@ECjKqOtb@vCzAhA~UbBzCtFz@ZlKbH~VbYvAnD|CfRgCpRj@nAzC_H{D_^q@_ENtC|CfRxB|a@nYvNbBLhLvI`Dt@bNElFcBrYaItDcArGQxNnDhStExAEeAnQqB_@Mv@p@j@uAxAIb@nCgBrv@dP|Pf[`YzEvK_BbSyW`c@gCdB?xLb@`GNdE}BqSfHcCyE~AB~g@eFtA|BqFaL~DiChIoJjPI~ApLlFrIbQv^"
    ],
    'BU04': [
      "yjvfGlmkKcKqU}CqFoGgKoAeLoNU@Q@o@C`Aq@?oNbNs@h@yTbL{CL}HqHbDfEKc@[y@oCiEw@fEx@|KjEmChALLCnA]l]_EtA|BqFaLyTbLov@e@_d@pZuSeE}`@{k@mMwGkXgD{WeEgA]GsF`HrAcDpE`TrDSrK_a@}GwM_N{YqAoPoCi@hDdAvLpB_B`AtDfG}A~C}@OwAYfBmJrCyNLq@CgMsIqBgA}EkJpG}@gITsSuGuEim@y@{EgWwZmBQmOwMuTsFo@u@sE\\wXaEmHmKyIqhAsKcY{g@e@mCrAiE~AsBTa@yD_v@`@oAGgAz@kAEwE|C",
      "emkgGbibKbAc@xVgOlADfXyApGOl@NbBcA`YqDpUjNpHdhAlLvY|SlCtIl@zAhA~UbBzCtFz@ZlKbH~VbYvAnD|CfRgCpRj@nAzC_H{D_^q@_ENtC|CfRxB|a@nYvNbBLhLvI`Dt@bNElFcBrYaItDcArGQxNnDhStExAEeAnQqB_@Mv@p@j@uAxAIb@nCgBrv@dP|Pf[`YzEvK_BbSyW`c@gCdB?xLb@`GNdE}BqSfHcCyE~AB~g@eFtA|BqFaL~DiChIoJjPI~ApLlFrIbQv^"
    ],
    'BU05': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU06': [
      "emxfGztiK}OdWmCBtAuKhFe`@aEsOWV}DpDcPc\\s@|EuEbCrCdRb@SxItJ~RhDrAqIg^iz@eNqS??sSwUyP{DyBeAoL_IuIwEu@e@gNoGmL_GsP_D{MuI_b@_X}NuMsE|@WqMmMeQyJaAgGRwCgMgAjEtCdB??LSmCoJaHs@GCq@lCnMdHJFLS{A}G{A|IgE@kF`NgA}@xBp@x@_IlHwBl@qDnDpFwYfT}Gh@{]|@aH`VaBGgE]iA@_ZpPsM{WiEvCsBpIsAo@TyAUxArAn@jDcL}BiDyBuKcBoGoCuHiFo@g@YU{AYr@??CN\\cAzBuBaFqVkJaUyABjGvJvBhHiHeTc@_F",
      "mwfgGxm_K`BhL@rC`HvOhAdEbH|UdPnKz@zEgE|E{@aBcDmKwF}@g@Yf@XpG|AzCpMnDlNULq@]TMJGa@TLh@x@YbAbFCDsBpIsAo@TyAUxArAn@nGiNlLnUfCjArGaIvOoFhAAxABFbG~@kDm@I^u@NIzArAnAVbEoW~NkAp@nDlCA`AuIbK_KwJkMi@}FlCeBFZhE}AUa@nFcCxKnHQr@Qr@nMdHd@rC~RL~NnRFnLnC~@xEcAtIxJpCfFb^pRYt@e@_AqCqCs@fB`BhBbDu@|M|IhLvBdB\\bYvLf@bAhN`HdKjH|B`A~NrEnVvUhNxS~Vtl@SPaEvDcPc\\s@|EuEbCrCdRb@SxItJ~RhDJu@x@_GGe@qFtc@wAzKzAVtM_PrF_EuBmACJ"
    ],
    'BU07': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU08': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU09': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU10': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU11': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU12': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU13': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU20E': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU40G': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ],
    'BU41G': [
      "{`_gGt`jKsAzAIb@nCgBbBiToUgEgA]GsF`HrAcDpE`TrDSrK_a@}GwM_N{YqAoPoCi@hDdAvLpB_B`AtDfG}A~C}@OwAYfBmJrCyNLq@CgMsIqBgA}EkJvAiCxDjAgITsSuGuEim@y@{EgWwZmBQmOwMiF{EkMWo@u@ac@sKwFnDaOrZqLxIkEjFeL~Ie@p@iObPoF|EuJvAeHwL{Tek@BuFs@hDdCxHzCtHqFcPxDaa@iDen@y@{CyHiY`QqKvDuB~JPG{Ewi@gAgAz@kAEwE|Co@b@`DnL",
      "azkgGldbKjEjOxBgApOlW|Clj@eFt\\bDjLnWfn@zK~@f}@}y@nD_CFg@ECjKqOpe@`FvNqApGfFzPbOm@k@wUcT{O}Aac@sKwFnDaOrZqLxIqRjQ_XrXiLr@cMqXQuBP|CnGzOrFnHlHQfIoG|KaKjDuCdJuKnU_TnD_CFg@ECjKqOtb@vCzAhA`NuA|FxDzCtFz@ZlKbH~VbYvAnD|CfRgCpRj@nAzC_H{D_^q@_ENtC|CfREjX~BpHxDdFvMt@|DzEbBLhLvI`Dt@bNElFcBrYaItDcArGQxNnDhStExAEeAnQqB_@Mv@p@j@"
    ],
    'BU43G': [
      "yjvfGlmkKcKqU}CqFoGgKoAeLoNU@Q@o@C`Aq@?oNbNs@h@yTbL{CL}HqHbDfEKc@[y@oCiEw@fEx@|KjEmChALLCnA]l]_EtA|BqFaLyTbLov@e@_d@pZuSeE}`@{k@mMwGkXgD{WeEgA]GsF`HrAcDpE`TrDSrK_a@}GwM_N{YqAoPoCi@hDdAvLpB_B`AtDfG}A~C}@OwAYfBmJrCyNLq@CgMsIqBgA}EkJpG}@gITsSuGuEim@y@{EgWwZmBQmOwMuTsFo@u@ac@sKyVb`@qLxIkEjFeL~Ie@p@iObPoF|EuJvAeHwL{Tek@BuFpAbNzCtHqFcPxDaa@iDen@y@{CyHiY`QqKvDuB~JPG{Ewi@gAgAz@kAEwE|Co@b@`DnL",
      "azkgGldbKjEjOxBgAdBzCjLpRzAnuAxJlVnGzO`P|GfIoG|KaKjDuCdJuKnU_TnD_CFg@ECjKqOtb@vCzAhA~UbBzCtFz@ZlKbH~VbYvAnD|CfRgCpRj@nAzC_H{D_^q@_ENtC|CfRxB|a@nYvNbBLhLvI`Dt@bNElFcBrYaItDcArGQxNnDhStExAEeAnQqB_@Mv@p@j@uAxAIb@nCgBrv@dP|Pf[`YzEvK_BbSyW`c@gCdB?xLb@`GNdE}BqSfHcCyE~AB~g@eFtA|BqFaL~DiChIoJjPI~ApLlFrIbQv^"
    ],
    'BU47G': [
      "mfhgG|keQhAe@tF_DvT_K|@_@t@QhDyP|DmExEaBfJ`C`CfESn@]l@uCfPjAb@dHtA|BvBfRdR@xA~@r@fE}CpIiBjCXV|@r@g@cGsOJcB`WiGnEcPCwAqD|PiExBiSfE~F~PUVr@fAlCtMcB|CRjFF`AuCw@kCdFN|C{Bo@e@tBsAhEvDvNaBlIwIoCo@g@r@kLeCmUgBeE{DaCaDLaGcD_D\\iEhHmAx@dBnQvCzWv@`D|CrNTdAkCrGq@a@sEWoAPPvIgCvFkDr@eEiCgAoFVsHg@{PXg@u@}FmQqFgBw@_CgHeCdBeBnAgNcDaAkBdBkDdDeG`AsIBSyK{M_CaEjBsGjJ}F",
      "mkhgGtleQmE`B_A\\XfBrEsCbB`ImDfHe@|@fInJ?bCo@tFoDxGsAvDp@~ABV{@L]|HVdBhNcHdGfA~Bz@~IlC~An@t@zJjA?\\CTxXIxCrAbHjDjEz@lAfGUrDmBdD{FbFgTv@}An@aAkDqNe@eC{DwX{@oPxB_FbDuDvKrDfF|@xBfEhClV}@jJ_@dBnEr@`FzBnByHd@o@aFaPO{AgBuKdIdGDDj@OO}CjCeFnBj@vBXr@i@J_Hk@oBkDuNuB_I{BuFJcBpRgEvDmBfDwNCwA_FpRwXpGoLpHcDxEe@?MNmLmKaDgDcIiBhBmO~@cCI}AgCmBiLwBuIdHmEvNaD|AoRtJiFtB_F~B"
    ]
  }

  const lineasData = data.lineas.edges
    .sort((lineaA, lineaB) => {
      return lineaA.node.label > lineaB.node.label ? 1 : -1;
    })
    .map((linea) => {
    const rutasData = linea.node.rutas.map((ruta, rutaIndex) => {
      const rutaPoints = ruta.paradas.map((parada) => {
        return [parseFloat(parada.coord.lat), parseFloat(parada.coord.lon)];
      });

      const rutaMarkers = ruta.paradas.map((parada, paradaIndex) => {
        const key = `${linea.node.nombre}-${rutaIndex}-${paradaIndex}`
        return (
          <Marker position={[parada.coord.lat, parada.coord.lon]} icon={MapMarkerParadaIcon()} key={key}>
            <Popup>
              <span className="font-weight-bold">{parada.nombre}</span>
              <br/>
              <span>{linea.node.nombre}</span>
            </Popup>
          </Marker>
        )
      });

      return {
        points: rutaPoints,
        markers: rutaMarkers
      }
    })

    return {
      nombre: linea.node.nombre,
      markers: rutasData[0].markers.concat(rutasData[1].markers),
      points: rutasData[0].points.concat(rutasData[1].points),
      polylines: geometriasRutas[linea.node.label]
    }
  });

  const nuestrasLineas = lineasData.map((linea, index) => {
    const features = turf.points(linea.points);
    const _center = turf.center(features);

    const _polylines = linea.polylines.map((polyline, index) => {
      const positions = polyUtil.decode(polyline);
      return <Polyline key={index} pathOptions={redOptions} positions={positions} />
    });

    return {
      markers: linea.markers,
      polylines: _polylines,
      center: _center.geometry.coordinates
    }

  })

  return (
    <LangContext.Consumer>
      { context => (
        <Layout>
          <SEO title={context.translate(data, 'lineasyhorarios', 'titulo')}/>
          <Container className="py-5 mb-5">
            <PageTitle title={context.translate(data, 'lineasyhorarios', 'titulo')}/>

            <Row>
              <Col xs="12" md={{size: 10, offset:1}} lg={{size:8, offset:2}}>
                <div className="d-flex flex-row justify-content-center align-items-center pt-3 pb-4 px-5">
                  <Input className="text-capitalize" type="select" name="linea" id="linea" placeholder={"Selecciona una Línea"} onChange={(e) => {toggle(e.target.value)}}>
                    {context.translate(data, 'lineasyhorarios', 'lineas').map(linea =>
                      <option key={linea.identificador} value={linea.identificador} className="text-capitalize">{linea.linea}</option>
                    )}
                  </Input>
                </div>
              </Col>
            </Row>

            <div className="d-flex flex-row justify-content-center align-items-center pb-4">
              <span
                role="button"
                tabIndex={0}
                className={`kbus-line-subtab-selector rounded-pill px-4 py-1 ${activeSubTab === 'itinerarios' ? 'kbus-line-subtab-active' : 'kbus-line-subtab-inactive'}`}
                onClick={() => {toggleSubTab("itinerarios");}}
                onKeyDown={() => {toggleSubTab("itinerarios");}}
              >
                {context.translate(data, 'lineasyhorarios', 'itinerarios')}
              </span>
              |
              <span
                role="button"
                tabIndex={0}
                className={`kbus-line-subtab-selector rounded-pill px-4 py-1 ${activeSubTab === 'paradas' ? 'kbus-line-subtab-active' : 'kbus-line-subtab-inactive'}`}
                onClick={() => {toggleSubTab("paradas");}}
                onKeyDown={() => {toggleSubTab("paradas");}}
              >
                {context.translate(data, 'lineasyhorarios', 'paradas')}
              </span>
              |
              <span
                role="button"
                tabIndex={0}
                className={`kbus-line-subtab-selector rounded-pill px-4 py-1 ${activeSubTab === 'horarios' ? 'kbus-line-subtab-active' : 'kbus-line-subtab-inactive'}`}
                onClick={() => {toggleSubTab("horarios");}}
                onKeyDown={() => {toggleSubTab("horarios");}}
              >
                {context.translate(data, 'lineasyhorarios', 'horarios')}
              </span>
            </div>

            <Row>
              {context.translate(data, 'lineasyhorarios', 'lineas').map((linea, index) => {
                return linea.horarios.horarios.map((horario) => {
                  const tablas = horario.tablas.map((tabla, tablaIndex) => (
                    <table className={classnames({"table kbus-table-horarios": true, "mr-lg-4": tablaIndex === 0})}>
                      <thead>
                      <tr>
                        <th colSpan={tabla.paradas.length}>
                          <span>{tabla.origen}</span>
                          <img src={tablaIndex === 0 ? IdaIcon : VueltaIcon} alt="ida" className="mx-4"/>
                          <span>{tabla.destino}</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr>
                        {tabla.paradas.map((parada, index) => (<td key={index}>{parada}</td>))}
                      </tr>
                      {tabla.tiempos.map((tiempo, i) => {
                        const tiempos = tiempo.values.map((v, j) => (<td key={j}>{v}</td>))
                        return (
                          <tr className={(classnames({'kbus-horario-extension': tiempo.special}))}>{tiempos}</tr>
                        )
                      })}
                      </tbody>
                    </table>
                  ))

                  return (
                    <Col
                      xs="12" md={{size: 10, offset: 1}}
                      className={classnames({
                        'd-none': activeTab != linea.identificador || activeSubTab !== 'horarios',
                        'mt-4': true
                      })}
                      key={linea.identificador}
                    >
                      <h5 className="text-uppercase text-center border-bottom pb-2">{horario.titulo}</h5>
                      <div className='d-flex w-100 flex-column flex-lg-row justify-content-between mt-3'>
                        {tablas}
                      </div>
                    </Col>
                  )
                })
              })}

              {context.translate(data, 'lineasyhorarios', 'lineas').map((linea, index) => {
                const _filtered = data.lineas.edges.filter ((_linea) => {
                  return _linea.node.label == linea.linea.split(' ')[0];
                });

                if (_filtered.length === 0) {
                  return null;
                }

                const rutas = _filtered[0].node.rutas;

                const paradasIda = rutas[0].paradas.map((parada, index) => (
                  <div className="d-flex flex-row justify-content-end py-2" key={index}>
                    <span className="mx-2">{parada.nombre}</span>
                    <img src={OvalParadaIcon} alt={parada.nombre}/>
                  </div>
                ))

                const paradasVuelta = rutas[1].paradas.reverse().map((parada, index) => (
                  <div className="d-flex flex-row-reverse justify-content-end py-2" key={index}>
                    <span className="mx-2">{parada.nombre}</span>
                    <img src={OvalParadaIcon} alt={parada.nombre}/>
                  </div>
                ))

                return (
                  <Col
                    xs="12" md={{size: 10, offset: 1}} lg={{size: 8, offset: 2}}
                    className={classnames({'d-none d-lg-none': activeTab != linea.identificador || activeSubTab !== 'paradas', 'd-none d-lg-block': activeTab == linea.identificador && activeSubTab == 'paradas', 'mt-4': true})}
                    key={linea.identificador}
                  >
                    <h4 className="text-center text-muted pb-4">{rutas[0].nombre.split(">")[0].trim()}</h4>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="pt-0 pb-5 d-flex flex-column justify-content-between">
                        <div className="d-flex flex-row justify-content-end">
                          <img className="kbus-paradas-ciclo-flecha" src={FlechaOval} alt="ida"/>
                        </div>
                        <div className="d-flex flex-grow-1 flex-column justify-content-around kbus-paradas-ida">
                          {paradasIda}
                        </div>
                      </div>
                      <div className="d-flex flex-column justify-content-center">
                        <div className="kbus-paradas-ciclo-top"></div>
                        <div className="kbus-paradas-ciclo-mid flex-grow-1"></div>
                        <div className="kbus-paradas-ciclo-bottom"></div>
                      </div>
                      <div className="pt-5 pb-0 d-flex flex-column justify-content-between">
                        <div className="flex-grow-1 d-flex flex-column justify-content-around kbus-paradas-vuelta">
                          {paradasVuelta}
                        </div>
                        <div className="d-flex flex-row justify-content-start">
                          <img className="kbus-paradas-ciclo-flecha vuelta" src={FlechaOval} alt="vuelta"/>
                        </div>
                      </div>
                    </div>
                    <h4 className="text-center text-muted pt-4">{rutas[1].nombre.split(">")[0].trim()}</h4>
                  </Col>
                )
                }
              )}

              {context.translate(data, 'lineasyhorarios', 'lineas').map((linea, index) => {
                  const _filtered = data.lineas.edges.filter ((_linea) => {
                    return _linea.node.label == linea.linea.split(' ')[0];
                  });

                  if (_filtered.length === 0) {
                    return null;
                  }

                  const rutas = _filtered[0].node.rutas;

                  const paradasIda = rutas[0].paradas.map((parada, index) => (
                    <div className="d-flex flex-row-reverse justify-content-end py-2" key={index}>
                      <span className="mx-2">{parada.nombre}</span>
                      <img src={OvalParadaIcon} alt={parada.nombre}/>
                    </div>
                  ))

                  const paradasVuelta = rutas[1].paradas.reverse().map((parada, index) => (
                    <div className="d-flex flex-row-reverse justify-content-end py-2" key={index}>
                      <span className="mx-2">{parada.nombre}</span>
                      <img src={OvalParadaIcon} alt={parada.nombre}/>
                    </div>
                  ))

                  return (
                    <Col
                      xs="12" md={{size: 10, offset: 1}} lg={{size: 8, offset: 2}}
                      className={classnames({'d-none d-lg-none': activeTab != linea.identificador || activeSubTab !== 'paradas', 'd-block d-lg-none': activeTab == linea.identificador && activeSubTab == 'paradas', 'mt-4': true})}
                      key={linea.identificador}
                    >
                      <h4 className="text-center text-muted pb-4">{rutas[0].nombre.split(">")[0].trim()}</h4>
                      <div className="d-flex flex-row justify-content-start ml-4">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="kbus-paradas-ciclo-mobile flex-grow-1"></div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div className="d-flex flex-grow-1 flex-column justify-content-around kbus-paradas-mobile">
                            {paradasIda}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center text-muted pt-4">{rutas[1].nombre.split(">")[0].trim()}</h4>

                      <div className="py-5"></div>

                      <h4 className="text-center text-muted pb-4">{rutas[1].nombre.split(">")[0].trim()}</h4>
                      <div className="d-flex flex-row justify-content-start ml-4">
                        <div className="d-flex flex-column justify-content-center">
                          <div className="kbus-paradas-ciclo-mobile flex-grow-1"></div>
                        </div>
                        <div className="d-flex flex-column justify-content-between">
                          <div className="d-flex flex-grow-1 flex-column justify-content-around kbus-paradas-mobile">
                            {paradasVuelta}
                          </div>
                        </div>
                      </div>
                      <h4 className="text-center text-muted pt-4">{rutas[0].nombre.split(">")[0].trim()}</h4>
                    </Col>
                  )
                }
              )}

              <Col xs="12" md={{size: 10, offset:1}} lg={{size:8, offset:2}}>
                <div className={classnames({'d-none': activeSubTab !== 'itinerarios', 'w-100': true})} style={{height: '500px'}}>
                  <Map centroMapa={nuestrasLineas[activeTab-1].center} zoomMapa={13} styleMapa={{height: '100%', width: '100%'}}>
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {nuestrasLineas[activeTab-1].markers}
                    {nuestrasLineas[activeTab-1].polylines}
                  </Map>
                </div>
             </Col>
            </Row>

          </Container>
        </Layout>
      )}
    </LangContext.Consumer>
  )
}

export default LineasYHorariosPage

export const pageQuery = graphql`
  query {
    lineasyhorarios: strapiLineasYHorarios {
      horarios
      titulo
      itinerarios
      paradas
      lineas {
        linea
        identificador
        paradas {
          publicURL
        }
        horarios {
            horarios {
              titulo
              tablas {
                origen
                destino
                paradas
                tiempos {
                  special
                  values
                }
              }
            }
          }
      }
    }
    lineasyhorarios_eus: strapiLineasYHorariosEus {
      horarios
      titulo
      itinerarios
      paradas
      lineas {
        linea
        identificador
        paradas {
          publicURL
        }
        horarios {
            horarios {
              titulo
              tablas {
                origen
                destino
                paradas
                tiempos {
                  special
                  values
                }
              }
            }
          }
      }
    }
    lineas: allStrapiLineas {
      edges {
        node {
          label
          nombre
          rutas {
            direccion
            nombre
            paradas {
              nombre
              label
              coord {
                lat
                lon
              }
            }
          }
        }
      }
    }
  }
`